<template>
    <div class="uc-page">
        <div class="uc-grid flex-center" style="position: relative">
            <div class="uc-avatar">
                <img v-if="user.headphoto" :src="user.headphoto">
                <img v-else src="../../assets/pictures/user-circle.png">
            </div>
            <div class="flex_bd">
                <div class="uc-name flex-center" v-if="user && user.username" >
                    <span style="font-size:18px;margin-right:5px">{{user.username}}</span>
                    <!-- <div @click="url('/authentication?is_auth_set='+is_auth_set)" class="status-span status-warning" v-if="is_auth_set == -1">
                        <van-icon name="warn-o" size="18" color="#ff7300"/> <span>{{$t('未认证')}}</span>
                    </div>
                    <div @click="url('/authentication?is_auth_set='+is_auth_set)" class="status-span status-warning" v-if="is_auth_set == 0">
                        <van-icon name="warn-o" size="18" color="#ff7300"/> <span>{{$t('审核中')}}</span>
                    </div>
                    <div @click="url('/authentication?is_auth_set='+is_auth_set)" class="status-span status-success" v-if="is_auth_set == 1">
                        <van-icon name="success" size="18" color="#46ab87"/> <span>{{$t('已认证')}}</span>
                    </div>
                    <div @click="url('/authentication?is_auth_set='+is_auth_set)" class="status-span status-danger" v-if="is_auth_set == 2">
                        <van-icon name="cross" size="18" color="#ff0000"/> <span>{{$t('已拒绝')}}</span>
                    </div> -->
                </div>
                <div class="uc-invte flex-center" >
                    <div class="text" >{{$t('邀请码')}}</div>
                    <div class="code">{{user.share_code}}</div>
                    <span class="iconfont icon-fz_c btn-copy" data-clipboard-action="copy"
                          :data-clipboard-text="user.share_code" @click="copy"></span>
                </div>
            </div>
            <router-link class="btn btn-lang" to="/lang" v-if="select_lang > 0">
                <span class="iconfont icon-yuyan"></span>
            </router-link>
        </div>
        <div class="uc-info-card">
            <div class="bal-cell" v-if="user && user.username">
                <div class="bal-text">{{$t('我的钱包余额')}}</div>
                <div class="bal-value">
                    {{parseFloat(user.balance).toFixed(6)}} <span style="font-size: 30px">{{$t('USDT')}}</span>
                </div>
            </div>
            <div class="bal-cell flex-center" v-if="user && user.username">
                <div class="flex_bd">
                    <div class="bal-text">{{$t('冻结本金')}}</div>
                    <div class="bal-value">
                        {{parseFloat(user.freeze).toFixed(2)}} <span style="font-size: 30px">{{$t('USDT')}}</span>
                    </div>
                </div>
            </div>
            <div class="uc-data-row" v-if="user.stat" >
                <!-- <div class="uc-col">
                    <div class="uc-label" style="padding-right: 20px">{{$t('今日代理收益')}}</div>
                    <div class="uc-val">{{parseFloat(user.stat.today_agent_incomes).toFixed(6)}}</div>
                </div> -->
                <div class="uc-col">
                    <div class="uc-label" style="padding-right: 20px">{{$t('今日个人收益')}}</div>
                    <div class="uc-val">
                        {{splitNumber(user2.money)[0]}}.{{splitNumber(user2.money)[1]}} <span>{{$t('USDT')}}</span>
                    </div>
                </div>
                <!-- <div class="uc-col">
                    <div class="uc-label" style="padding-right: 20px">{{$t('代理累计收益')}}</div>
                    <div class="uc-val">{{parseFloat(user.stat.total_agent_incomes).toFixed(6)}}</div>
                </div> 
                <div class="uc-col">
                    <div class="uc-label" style="padding-right: 20px">{{$t('机器人返佣')}}</div>
                    <div class="uc-val">{{parseFloat(user.stat.total_team_incomes).toFixed(6)}}</div>
                </div> -->
                <!-- <div class="uc-col">
                    <div class="uc-label" style="padding-right: 20px">{{$t('今日团队收益')}}</div>
                    <div class="uc-val">
                        {{parseFloat(user.stat.today_agent_incomes).toFixed(6)}}
                    </div>
                </div> -->
                
            </div>
            <div class="bal-bar" @click="url('/robot')">
                <img src="../../assets/pictures/logo.png" class="bal-ico" >
                <div class="flex_bd">{{$t('查看我的机器人仓库')}}</div>
                <van-icon name="arrow" />
            </div>
        </div>
        <div class="uc-bar">
            <img src="../../assets/pictures/kefu2.png" class="ico" >
            <div class="flex_bd" v-if="user.r_count > 0">{{$t('当前共有')}} {{user.r_count}} {{$t('台')}} {{$t('机器人自动做单中')}}</div>
            <div class="flex_bd" v-else>{{$t('您当前没有机器人正在工作')}}</div>
        </div>
        <div class="uc-panel">
            <div class="hor-menu">
                <div class="col">
                    <router-link to="/trade-record" class="menu-link">
                        <div class="link-icon">
                            <img src="../../assets/pictures/uc-menu-1.png" alt="">
                        </div>
                        <div class="link-label">{{$t('交易记录')}}</div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link to="/income" class="menu-link">
                        <div class="link-icon">
                            <img src="../../assets/pictures/uc-menu-2.png" alt="">
                        </div>
                        <div class="link-label">{{$t('收入记录')}}</div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link to="/cost" class="menu-link">
                        <div class="link-icon">
                            <img src="../../assets/pictures/uc-menu-3.png" alt="">
                        </div>
                        <div class="link-label">{{$t('消费记录')}}</div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link to="/withdraw-record" class="menu-link">
                        <div class="link-icon">
                            <img src="../../assets/pictures/uc-menu-4.png" alt="">
                        </div>
                        <div class="link-label">{{$t('取款记录')}}</div>
                    </router-link>
                </div>
                <div class="col">
                    <router-link to="/deposit-record" class="menu-link">
                        <div class="link-icon">
                            <img src="../../assets/pictures/uc-menu-5.png" alt="">
                        </div>
                        <div class="link-label">{{$t('存款记录')}}</div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="uc-panel">
            <div class="uc-card"  @click="setting_show = true">
                <img src="../../assets/pictures/uc-safe.png" alt="" class="uc-ico">
                <div class="flex_bd">{{$t('安全中心')}}</div>
                <div class="iconfont icon-you"></div>
            </div>
            <div class="uc-card"  @click="openPdf">
                <img src="../../assets/pictures/uc-33.png" alt="" class="uc-ico">
                <div class="flex_bd">{{$t('白皮書')}}</div>
                <div class="iconfont icon-you"></div>
            </div>
            <div class="uc-card" @click="url('/trend')">
                <img src="../../assets/pictures/uc22.png" alt="" class="uc-ico">
                <div class="flex_bd">{{$t('行情')}}</div>
                <div class="iconfont icon-you"></div>
            </div>
            <div class="uc-card" @click="openService">
                <img src="../../assets/pictures/uc-4.png" alt="" class="uc-ico">
                <div class="flex_bd">{{$t('网上客服')}}</div>
                <div class="iconfont icon-you"></div>
            </div>
        </div>
        <div class="end-foot">
            <!-- <a class="van-button van-button--primary van-download" :href="download_url" target="_blank">
                <img src="../../assets/pictures/uc-6.png" alt="" class="uc-ico">
                <div class="download-label">{{$t('下载APP')}}</div>
            </a> -->
            <div class="uc-out" @click="handleClickExit" style="color: #79acbf;padding: 0">{{$t('退出登录')}}</div>
        </div>
        <Foot :active="3"/>
        <van-popup v-model="setting_show" position="right" class="safe-pop" closeable >
            <div class="uc-grid flex-center" style="padding: 80px 0 10px 12px;margin-bottom: 0;align-items: center">
                <div class="uc-avatar uc-avatar-setting" style="margin-right: 10px">
                    <img v-if="user.headphoto" :src="user.headphoto">
                    <img v-else src="../../assets/pictures/default-avatar.jpg" style="width: 40px;height: 40px;border-radius: 50%">
                </div>
                <div class="flex-bd">
                    <div class="uc-name" v-if="user && user.username" style="font-size:18px">
                        <div class="flex-center">
                            <span style="font-size:18px;margin-right:5px">{{user.username}}</span>
                            <img v-if="is_auth_set == 1" src="../../assets/pictures/verified.png" width="18" alt="">
                        </div>
                        <div style="font-size: 14px">{{user.phone}}</div>
                    </div>
                </div>
                <!-- <div class="sale-auth">
                    <div class="s-btn" v-if="user.is_code_set == 1 || user.is_pay_set == 1 || user.is_email_set == 1">{{$t('保护中')}}</div>
                    <div class="s-btn " v-else>{{$t('存在风险')}}</div>
                </div> -->
            </div>
            <div class="uc-panel-setting ">
                <div class="uc-card-setting" @click="url('/bank')">
                    <div class="iconfont"><img src="../../assets/pictures/uc-1.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('我的银行卡')}}</div>
                    <div class=" safe-set">
                        <van-icon name="arrow" class="safe-set2-icon" color="#ffffff" />
                    </div>
                </div>
                <!-- <div class="uc-card-setting" @click="url('/email-set')">
                    <div class="iconfont"><img src="../../assets/pictures/email-preview.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('设置Email')}}</div>
                    <div class=" safe-set" v-if="user.is_email_set == 0">
                        <div class="sefe-set-btn theme_5">{{$t('去设置')}}</div>
                    </div>
                    <div class=" safe-set" v-else>
                        <div class="sefe-set-btn theme_5" style="background-color: #394d58">{{$t('已设置')}}</div>
                    </div>
                </div>
                <div class="uc-card-setting" @click="url('/code-set')">
                    <div class="iconfont"><img src="../../assets/pictures/uc-9.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('设置谷歌码')}}</div>
                    <div class="safe-set" v-if="user.is_code_set == 0">
                        <div class="sefe-set-btn theme_5">{{$t('去设置')}}</div>
                    </div>
                    <div class="flex_bd safe-set" v-else>
                        <div class="sefe-set-btn theme_5" style="background-color: #394d58">{{$t('已设置')}}</div>
                    </div>
                </div> -->
                <div class="uc-card-setting" @click="url('/pay-set')">
                    <div class="iconfont"><img src="../../assets/pictures/uc-5.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('设置交易密码')}}</div>
                    <div class="safe-set" v-if="user.is_pay_set == 0">
                        <div class="sefe-set-btn theme_5">{{$t('去设置')}}</div>
                    </div>
                    <div class=" safe-set" v-else>
                        <div class="sefe-set-btn theme_5" style="background-color: #394d58">{{$t('已设置')}}</div>
                    </div>
                </div>
                <div class="uc-card-setting" @click="url('/login-pwd')">
                    <div class="iconfont"><img src="../../assets/pictures/uc-5.png" width="20"></div>
                    <div class="flex_bd theme_5">{{$t('更改登录密码')}}</div>
                </div>
                <!-- <div class="uc-card-setting" @click="url('/authentication?is_auth_set='+is_auth_set)">
                    <div class="iconfont"><img src="../../assets/pictures/uc-safe.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('身份验证')}}</div>
                    <div class=" safe-set" v-if="is_auth_set == -1">
                        <div class="sefe-set-btn theme_5">{{$t('去设置')}}</div>
                    </div>
                    <div class=" safe-set" v-else-if="is_auth_set == 0">
                        <div class="sefe-set-btn theme_5">{{$t('待审核')}}</div>
                    </div>
                    <div class=" safe-set" v-else-if="is_auth_set == 1">
                        <img src="../../assets/pictures/verified.png" width="18" height="18" alt="">
                    </div>
                </div> -->
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";
    import Clipboard from "clipboard";

    export default {
        components: {
            Foot
        },
        data() {
            return {
                user2: {name: '', money: 0.00, balance: 0.00},
                user: {},
                setting_show: false,
                download_url: '#',
                select_lang: false,
                i18n_lang:"",
                is_auth_set:0,
            }
        },
        methods: {
            openService(){
                window._vue.openService();
            },
            openPdf(){
                if(this.i18n_lang == 'TW'){
                    window.open(`${window.location.origin}/whitepaper-tw.pdf`);
                }else{
                    window.open(`${window.location.origin}/whitepaper.pdf`);
                }
                
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            copy() {
                let clipboard = new Clipboard(".btn-copy");
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            getData() {
                // setTimeout(()=>{
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user = res.data;
                    });
                // }, 20002);
            },
            handleClickExit() {
                this.$helper.forgetToken();
                location.href = "/#/login";
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            getIdentity() {
                this.$axios
                    .get(this.$api_url.get_identity_auth)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.is_auth_set = res.data.data.is_auth;
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                    });
            },
            getHomeData() {
                this.$axios
                    .get(this.$api_url.get_home_data)
                    .then((res) => {
                            if (res.data && res.data.code == 0) {
                                this.user2 = res.data.data.user;
                            }
                        }
                    )
                    .catch((err) => {
                        console.log(err.message);
                        // this.$toast.fail(err.message);
                    });
            },
        },
        mounted() {
            this.download_url = localStorage.getItem('app.download_url') || '#';
            let download_url_android = localStorage.getItem('app.download_url_android') || '#';
            let download_url_ios = localStorage.getItem('app.download_url_ios') || '#';
            let deviceAgent = navigator.userAgent;
            let ios = deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/);
            if(ios){
                this.download_url = download_url_ios;
            }else{
                this.download_url = download_url_android;
            }

            let aqzx = localStorage.getItem("aqzx") || '';
            if (aqzx > 0) {
                this.setting_show = true;
            }
            localStorage.removeItem("aqzx");

            this.select_lang = localStorage.getItem('app.select_lang') || false;
            this.i18n_lang = localStorage.getItem('i18n_lang') || 'zh';
            
            this.getData();
            this.getHomeData()
            this.getIdentity()
        },
    }
</script>
<style scoped>
    .status-span{
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        height: 24px;
        line-height: 1;
        padding: 0 6px;
        font-size: 12px;
    }
    .status-span span{
        margin-left: 3px;
    }
    .status-danger{
        background-color: rgba(255, 0, 0, .1);
        color: #ff0000;
    }
    .status-warning{
        background-color: rgba(246, 140, 64, .1);
        color: #ff7300;
    }
    .status-success{
        background-color: rgba(85, 246, 64, .1);
        color: #46ab87;
    }

</style>

